// React
import React from "react";
import Home from "containers/Home";
import PrivateRoute from "components/PrivateRoute";
import UserLogged from "../../containers/userLogged";

const H = () => {
  return (
    <UserLogged>
      <PrivateRoute component={Home} />
    </UserLogged>
  );
};

export default H;
