import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;

  @media(max-width: 768px) {
    display: flex;
    flex-flow: column;
  }
`;
