//React
import React, { useEffect } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
//Actions
import { doLogout } from "actions/login";
// import { getAllVotes, getAllVotesFromUser } from "actions/vote";
// Components
import Button from "components/Button/Button";
import TopBarLayout from "containers/Layout/Topbar/topBar";
// i18n
import { FormattedMessage } from "react-intl";
import globalMessages from "containers/messages"
import messages from "./messages";
//Utils
import { navigate } from "gatsby";
import Icon from "utils/icon";
import Vote from "images/pictures/vote.svg";
import ListUsers from "images/pictures/list_users.svg";
//Style
import styled from "styled-components";
import theme from 'theme';
import { Container } from "./style.js";

const Return = styled.div`
  padding: 16px 32px;
  border-bottom: 1px solid ${theme.ui.grey};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.darkgray};

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const SectionWrapper = styled.div`
  padding: 16px 32px;
  display: flex;
  gap: 16px;

  @media(max-width: 768px) {
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 16px;
    flex-flow: column;
    height: calc(100% - 100px);
  }
`;

const Section = styled.div`
  width: 50%;
  border-radius: 5px;
  background-color: ${theme.darkwhite};
  border: 1px solid ${theme.ui.blue};
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${theme.aliceblue};
    transition: all 0.3s;
  }

  @media(max-width: 768px) {
    width: calc(100% - 32px);
    flex-flow: column;
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 16px;
  align-items: center;

  @media(max-width: 768px) {
    flex-flow: column;
    width: 100%;
  }
`;

const Infos = styled.div`
  width: 50%;

  @media(max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.div `
  font-size: 18px;
  font-weight: bold;
  color :${theme.darkgray};

  @media(max-width: 768px) {
    text-align: center;
  }
`;

const Description = styled.div `
  color :${theme.mediumgray};
  padding-bottom: 16px;

  @media(max-width: 768px) {
    text-align: center;
  }
`;

const ButtonStyled = styled.div`
  background-color: ${theme.ui.blue};
  padding-left: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
`;

const Image = styled.div`
  width: 35%;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const AdminHome = () => {
  // Bind state
  const { userFront } = useSelector((state) => state.user);
  // Bind Actions
  const dispatch = useDispatch();
  const logout = () => dispatch(doLogout());
  // const get = () => dispatch(getAllVotes());
  // const getFromUser = (userId) => dispatch(getAllVotesFromUser(userId));

  //Use effect
  useEffect(() => {
    if (
      !userFront ||
      !localStorage.getItem("userFront") ||
      !localStorage.getItem("token")
    ) {
      disconnect(null);
    }
  }, []);

  const disconnect = (e) => {
    if (e) {
      e.preventDefault();
    }
    logout();
  };

  // const getAllVotesAction = () => {
  //   get();
  // };

  // const getAllVotesFromUserAction = () => {
  //   getFromUser(userFront.id);
  // };

  return (
    <Container>
      <TopBarLayout />
      <Return onClick={() => navigate("/")}>
        <Icon icon="LeftArrow" size="sm" />
        <FormattedMessage {...globalMessages.return} />
      </Return>
      <SectionWrapper>
        <Section onClick={() => navigate("/s")}>
          <TopSection>
            <Image>
              <img src={Vote} alt="" />
            </Image>
            <Infos>
              <Title>
              <FormattedMessage {...messages.deliberation} />
              </Title>
              <Description>
                <FormattedMessage {...messages.create_and_modify_deliberation} />
              </Description>
              <ButtonStyled>
                <Icon icon="Eye" size="sm"/>
                <Button>
                  <FormattedMessage {...messages.go_to_session} />
                </Button>
              </ButtonStyled>
            </Infos>
          </TopSection>
        </Section>
        <Section onClick={() => navigate("/ul")}>
          <TopSection>
            <Image>
              <img src={ListUsers} alt="" />
            </Image>
            <Infos>
              <Title>
                <FormattedMessage {...messages.list_user} />
              </Title>
              <Description>
                <FormattedMessage {...messages.create_and_modify_userlist} />
              </Description>
              <ButtonStyled>
                <Icon icon="Users" size="sm"/>
                <Button>
                  <FormattedMessage {...messages.list_user} />
                </Button>
              </ButtonStyled>
            </Infos>
          </TopSection>
        </Section>
      </SectionWrapper>
      {/*
        <Button action={() => navigate("/v/c")}>
          <FormattedMessage {...messages.create_vote} />
        </Button>
        <Button action={() => navigate("/v/l")}>
          <FormattedMessage {...messages.get_votes} />
        </Button>
        <Button action={() => getAllVotesFromUserAction()}>
          <FormattedMessage {...messages.get_votes_user} />
        </Button>
      */}
    </Container>
  );
};

export default AdminHome;
