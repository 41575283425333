import { defineMessages } from "react-intl";
export default defineMessages({
  connected: {
    id: "a2vote.connected",
    defaultMessage: "Connected",
  },
  create_vote: {
    id: "a2vote.create.vote",
    defaultMessage: "Create vote",
  },
  get_votes: {
    id: "a2vote.get.votes",
    defaultMessage: "Get votes",
  },
  get_votes_user: {
    id: "a2vote.get.votes.user",
    defaultMessage: "Get votes for a user",
  },
  list_user: {
    id: "a2vote.list.user",
    defaultMessage: "List of users",
  },
  go_to_session: {
    id: "a2vote.welcome.go_to_session",
    defaultMessage: "Access Sessions",
  },
  go_to_vote: {
    id: "a2vote.welcome.go_to_vote",
    defaultMessage: "Access Votes",
  },
  logout: {
    id: "a2vote.logout",
    defaultMessage: "Logout",
  },
  deliberation: {
    id: "a2vote.title.deliberation",
    defaultMessage: "Deliberation",
  },
  create_and_modify_deliberation: {
    id: "a2vote.create.modify.deliberation",
    defaultMessage: "Create and modify a deliberation session",
  },
  create_and_modify_userlist: {
    id: "a2vote.create.modify.userlist",
    defaultMessage: "Create and modify a userlist",
  }
});
